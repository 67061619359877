@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';
@import 'styles/mixins/fonts-phantom-grotesk';
@import 'styles/mixins/fonts-decorated';

.container {
	background-color: $white;
	color: $black;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 4.75rem;
	padding-top: $spacing-xl;
	padding-bottom: $spacing-lg;
	@include xs-only {
		gap: 4.125rem;
	}
	@include sm-down {
		padding: $spacing-lg 0 $spacing-md;
	}
	h3 {
		font-weight: 400;
		margin-bottom: 0px;
	}
}

.title {
	@include headline3;
	margin: 3rem;
	text-align: center;
	@include sm-down {
		@include headline4;
		margin: 1rem;
	}
}

.titleLight {
	@include headline3;
	color: rgba(0, 0, 0, 0.5);
}

.podInfo {
	display: flex;
	flex-direction: row;
	text-align: center;
	width: 100%;
	justify-content: center;
	margin-bottom: 2rem;
	@include xs-only {
		flex-direction: column;
		gap: 5rem;
	}
}

.podEyebrow {
	@include blue_tag;
}

.podTitle {
	@include headline4;
	color: $black;
	text-align: center;
}

.podInfoStarting {
	@include body3;
	color: #a6a6a6;
	text-align: center;
}

.podInfoPrice {
	@include body3;
	color: #1a3ebb;
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 0.5rem;

	@include sm-down {
		font-size: 1.125rem;
	}
}

.podHeader {
	margin: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
}

.podSubheader {
	@include body4;
	color: rgba(0, 0, 0, 0.5);
	text-align: center;
	max-width: 40ch;
	min-height: 1.4em;

	@include xs-only {
		min-height: unset;
	}
}

.feature {
	display: grid;
	grid-template-columns: 32px auto;
	column-gap: 0.5rem;
	margin-bottom: 0.5em;
	align-items: center;
}

.sideBySide {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 15rem;
	row-gap: 5px;
	width: fit-content;
	align-self: center;
}

.features {
	display: flex;
	flex-direction: column;
	gap: 7.5rem;
	width: 100%;
	align-items: center;
	padding: 0 6rem;
	@include xs-only {
		display: none;
	}
}

.featureList {
	max-width: 16.25rem;
	@include body3;
}

.podInfoIncludes {
	@include body5;
	color: #a6a6a6;
	text-align: center;
}

.infoBox {
	max-width: 630px;
}

.podInfoText {
	@include pg_body3;
	color: rgba(0, 0, 0, 0.5);
}

.podDescription {
	margin-bottom: 2rem;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.pill {
	@include button1;
	border-radius: 4px;
	font-weight: 500;
	color: #5289fe;
	background: rgba(82, 137, 254, 0.2);
	padding: 0.25em 0.625em;
	margin-bottom: 1rem;
	text-transform: uppercase;
}

.comparePodsCta {
	@include button2;
	color: #5289fe;
}

.icon {
	display: flex;
	margin: auto;
}

.mobFeatures {
	display: none;
	padding: 0 3rem;
	@include xs-only {
		display: inherit;
	}
}

.podImage {
	max-width: 600px;
	filter: brightness(1.2);
}

.comparison {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
}

.strikethrough {
	text-decoration: line-through;
	color: #717171;
}

.podInfoTag {
	@include orange_tag;
}

.ctaButton {
	span {
		@include body4;
	}
}

.featureSection {
	width: 100%;
	max-width: 1567px;
	display: flex;
	flex-direction: column;
	gap: 2.625rem;
	hr {
		width: 100%;
	}
	@include xs-only {
		gap: 1.5rem;
	}
	text-align: left;
}

.featureSectionHeader {
	@include headline6;
	@include xs-only {
		@include headline7;
	}
}

.compareTable {
	border: none;
	border-spacing: 1rem 0rem;

	width: 100%;
	max-width: 60rem;

	tr {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 2rem;
	}

	td,
	th {
		border-left: 1px solid #d3d3d3;
		border-right: 1px solid #d3d3d3;
		text-align: center;
		min-height: 70px;
		@include headline9;
	}
	th {
		padding-top: 1.5rem;
		height: auto;
		text-align: center;
	}
	tr:nth-child(2) {
		vertical-align: top;
	}
	td:first-child,
	th:first-child,
	thead > tr > th:first-child tfoot > tr > td:first-child {
		border: none;
		text-align: left;
		@include body4;
		min-width: 175px;
	}
	td:nth-child(3),
	td:nth-child(4) {
		background: #e6ebff;
	}
	th:nth-child(3),
	th:nth-child(4) {
		background: #e6ebff;
	}

	thead > tr > th {
		border-top: 1px solid #d3d3d3;
		border-radius: 8px 8px 0px 0px;
	}
	tfoot > tr > td {
		height: 20px;
		border-bottom: 1px solid #d3d3d3;
		border-radius: 0px 0px 8px 8px;
	}
	@include xs-only {
		display: none;
	}
}

.popularHeader {
	position: relative;
}

.thImage {
	height: 66px;
}

.thTitle {
	margin: 0 auto;
	text-align: center;
}

.popularTag {
	position: absolute;
	top: 0%;
	left: 50%;
	transform: translate(-50%, -50%);
	@include tag1;
	border-radius: 4px;
	font-weight: 500;
	color: #fff;
	background: #0038ff;
	padding: 5px 10px;
	margin-bottom: 1rem;
	text-transform: uppercase;
	@include xs-only {
		transform: translate(-50%, -52px);
	}
}

.fullScreen {
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	//max-width: 100% !important;
	//max-height: 100% !important;
	position: fixed;
	z-index: 1300;
	background-color: #fff;
}

.closeButton {
	position: fixed;
	top: 0%;
	right: 0%;
	display: flex;
	background-color: rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(8px);
	padding: 0.5rem;
	border-radius: 0.5rem;
	margin: 1rem;
	cursor: pointer;
	width: fit-content;
}

.fullScreenContent {
	height: 100%;
	padding: 5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-y: scroll;

	@include sm-down {
		padding: 4rem 2rem;
	}

	@include xs-only {
		padding: 1rem;
	}
}

.popUpHeader {
	@include headline6;
	margin-bottom: 5rem;

	@include xs-only {
		@include headline7;
		margin: 3rem 0;
	}
	text-align: center;
}

.sliderFeatureContainer {
	.sliderDescription,
	.sliderFeature {
		@include body5;
		text-align: left;
	}

	.sliderDescription {
		color: #717171;
		padding: 1rem 0;
		display: block;
	}

	.sliderFeature {
		width: 175px;
		margin: 0;
		margin: 0 5ch 0 auto;
	}
	:global(.arrow_slide_down_down),
	:global(.arrow_slide_down_up) {
		top: 0;
		bottom: auto;
		width: 12px;
	}
}

.featureCompareTableMob {
	display: none;
	@include xs-only {
		display: flex;
	}
	width: 343px;
	margin: 1rem auto;
	border: 1px solid #d3d3d3;
	border-radius: 8px;
	position: relative;
	flex-direction: column;
	align-items: center;
	gap: 1.75rem;
	hr {
		width: 100%;
	}
	hr:last-child {
		display: none;
	}
	padding: 2.5rem;
}

.featureInfoCheckmarks {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.featureCompareTableMobTitles {
	display: flex;
	justify-content: space-between;
	width: 100%;
	@include headline9;
	font-size: 1rem;
	text-align: center;
}

.featureInfo {
	@include body5;
	text-align: center;
}

.featureInfoDescription {
	color: #999;
}

.closerLook {
	border-radius: 0.5rem;
	background: #f2f2f2 !important;

	position: relative;
	z-index: 2;

	padding: 1rem 1.125rem !important;

	pointer-events: all;
	cursor: pointer;

	span {
		@include headline8;

		display: inline-flex;
		align-items: center;
		gap: 0.875rem;
	}

	&:hover,
	&:focus-visible {
		background: #d9d9d9 !important;
		scale: 1.01;
	}

	&:active {
		scale: 0.99;

		opacity: 0.8;
	}
}
.popularTableHeader {
	position: relative;
}
.divTitle {
	width: 42px;
}
.compareButton {
	padding: 1rem 1.125rem;
	@include xs-only {
		padding: 1rem;
	}
	span > svg {
		@include md-up {
			margin-left: 14px;
		}
	}
}
